import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UrlService } from '../../assets/url-service.service';
import { take } from 'rxjs/operators';
import { CountriesResponse, CountryDTO, CountryResponse } from '../models/countryDTO';
import { ResponseDTO } from '../models/ResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor(private http: HttpClient, private urlService: UrlService) { }

  public async getCountries() {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const res = await this.http.get<CountriesResponse>(urlRes.API_COUNTRIES).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.countries;
  }

  public async getCountryById(countryID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_COUNTRIES + '/' + countryID;
    const res = await this.http.get<CountryResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.country;
  }

  public async createCountry(country: CountryDTO) {
    const headers = new HttpHeaders().set('token', localStorage.getItem('token').replace(/"/g, ''));
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const res = await this.http.post<ResponseDTO>(urlRes.API_COUNTRIES, country, { headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
  }

  public async updateCountry(country: CountryDTO) {
    const headers = new HttpHeaders().set('token', localStorage.getItem('token').replace(/"/g, ''));
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_COUNTRIES + '/' + country.countryID;
    const res = await this.http.put<ResponseDTO>(url, country, { headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
  }

  public async addCountryToClient(countryId: number, clientId: number) {
    const body = { clientId, countryId };
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_COUNTRIES + '/clientcountry';
    const res = await this.http.post<ResponseDTO>(url, body).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
  }

  public async deleteCountryFromClient(countryId: number, clientId: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_COUNTRIES + `?clientId=${clientId}&countryId=${countryId}`;
    const res = await this.http.delete<ResponseDTO>(url).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
  }
}
