import { SampleDTO, SamplesResponse } from './../models/sampleDTO';
import { UrlService } from './../../assets/url-service.service';
import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { ResponseDTO } from '../models/ResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class SampleService {

  constructor(private urlService: UrlService, private http: HttpClient) { }

  public finishSample(sampleID: number, comment: string) {
    const finishSampleRequest = { comment };
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (resp: any) => {
          this.http.put(resp.API_SAMPLES + sampleID, finishSampleRequest).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          );
        }
      );
    });
  }

  public async enumPendingsByClient(clientID: number) {
    const body = { IDs: [clientID] };
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_SAMPLES + 'client';
    const res = await this.http.post<SamplesResponse>(url, body).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.samples;
  }

  public async createSample(sample: SampleDTO) {
    const body = {
      'AccountID': sample.accountID,
      'ClientID': sample.clientID,
      'ClientLegalName': sample.clientLegalName,
      'DateTimeSample': sample.dateTimeSample,
      'ProductPartNumber': sample.productPartNumber,
      'ProductID': sample.productID,
      'ContactId': sample.contactId
    };

    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const res = await this.http.post<ResponseDTO>(urlRes.API_SAMPLES, body).pipe(take(1)).toPromise();
    if (res.code == 400) { throw new Error('Número de Parte incorrecto.'); }
    if (res.code != 200) { throw new Error(res.message); }
  }

}
