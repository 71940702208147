import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar.component';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
registerLocaleData(localeEsAr, 'es-Ar');

@NgModule({
    imports: [RouterModule, CommonModule],
    declarations: [SidebarComponent],
    exports: [SidebarComponent],
    providers: [DatePipe, { provide: LOCALE_ID, useValue: 'es-Ar' }]
})

export class SidebarModule { }
