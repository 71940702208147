import { Pipe, PipeTransform } from '@angular/core';
import { GroupDTO } from '../models/groupDTO';

@Pipe({
  name: 'filterGroups'
})
export class FilterGroupsPipe implements PipeTransform {

  transform(value: GroupDTO[], arg: string): any {
    const resultData = [];
    arg = arg.toUpperCase().replace(/ /g, '');
    for(let i = 0; i < value.length; i++){
      if(value[i].description.toUpperCase().replace(/ /g, '').includes(arg)){
        resultData.push(value[i]);
      }
    }
    return resultData;
  }

}
