import { Pipe, PipeTransform } from '@angular/core';
import { QuotationDTO } from '../models/quotationDTO';

@Pipe({
  name: 'quotationFilter'
})
export class QuotationFilterPipe implements PipeTransform {

  transform(value: QuotationDTO[], arg: string): any {
    const arr: QuotationDTO[] = [];
    arg = arg.toUpperCase().replace(/ /g, '').replace('Ó', 'O');

    if(arg == '' || arg == null){
      return value;
    }
    else{
      for(let i = 0; i < value.length; i++){
        let status;
        if(value[i].status == 5 || value[i].status == 2){
          status = 'ENCURSO';
        }else if(value[i].status == 4){
          status = 'NOCOMPRO';
        }else if(value[i].status == 3){
          status = 'COMPRO';
        }else{
          status = 'PENDIENTE';
        }      
      if(value[i].quotationID.toString().toUpperCase().includes(arg) ||
        value[i].clientLegalName.toUpperCase().replace(/ /g, '').includes(arg) ||
        value[i].total.toString().toUpperCase().includes(arg) || status.includes(arg))
      {
        arr.push(value[i]);
      }
    }
    return arr;
  }

}
}
