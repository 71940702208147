import { Pipe, PipeTransform } from '@angular/core';
import { QuotationItemDTO } from '../models/quotationItemDTO';

@Pipe({
  name: 'quotationItemFilter'
})
export class QuotationItemFilterPipe implements PipeTransform {

  transform(value: QuotationItemDTO[], arg: any): any {
    const quotationItems: QuotationItemDTO[] = [];
    
    arg = arg.toString().toUpperCase().replace(/ /g, '');

    if(arg == null || arg == ''){
      return value;
    }else{
    for(let i = 0; i < value.length; i++){
      if(value[i].partialTotal.toString().toUpperCase().replace(/ /g, '').includes(arg) || 
      value[i].quantity.toString().toUpperCase().replace(/ /g, '').includes(arg) ||
      value[i].unitPrice.toString().toUpperCase().replace(/ /g, '').includes(arg) ||
      value[i].partNumber.toString().toUpperCase().replace(/ /g, '').includes(arg) ||
      value[i].discount.toString().toUpperCase().replace(/ /g, '').includes(arg) ||
      value[i].description.toUpperCase().replace(/ /g, '').includes(arg) ||
      value[i].deliveryTerm.toUpperCase().replace(/ /g, '').includes(arg)){
        quotationItems.push(value[i]);
      }
    }
    return quotationItems;}
  }

}
