import { Pipe, PipeTransform } from '@angular/core';
import { DiscountDTO } from '../models/discountDTO';

@Pipe({
  name: 'updateDiscounts'
})
export class UpdateDiscountsPipe implements PipeTransform {

  transform(value: DiscountDTO[], arg: DiscountDTO[]): any {
    return arg;
  }

}
