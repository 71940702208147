import { LoginDetailsDTO } from './../../models/loginDetailsDTO';
import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LoginServiceService } from '../../services/login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginDTO } from '../../models/loginDTO';
import { StorageService } from '../../services/storage.service';
import { UsersService } from '../../services/users.service';
import { ToastService } from '../../services/components/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;

@Component({
    selector: 'app-login-cmp',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
    test: Date = new Date();
    private toggleButton: any;
    private sidebarVisible: boolean;
    private nativeElement: Node;
    email = '';
    loginForm: FormGroup;
    loginDTO: LoginDTO;

    constructor(
        private element: ElementRef,
        private router: Router,
        private loginService: LoginServiceService,
        private storageService: StorageService,
        private usersService: UsersService,
        private toastService: ToastService,
        private spinner: NgxSpinnerService
    ) {
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.email, Validators.required]),
            password: new FormControl('', [Validators.required])
        });
        
    }

    ngOnInit() {
        let navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');
        body.classList.add('off-canvas-sidebar');
        const card = document.getElementsByClassName('card')[0];
        setTimeout(function () {
            // after 1000 ms we add the class animated to the login/register card
            card.classList.remove('card-hidden');
        }, 700);
        this.isLogged();
    }

    private isLogged() {
        const token = localStorage.getItem('token');
        const account = localStorage.getItem('account');
        if (token && account) {
            const user = <LoginDetailsDTO>JSON.parse(account);
            this.redirect(user);
        }
    }

    sidebarToggle() {
        let toggleButton = this.toggleButton;
        let body = document.getElementsByTagName('body')[0];
        let sidebar = document.getElementsByClassName('navbar-collapse')[0];
        if (this.sidebarVisible == false) {
            setTimeout(function () {
                toggleButton.classList.add('toggled');
            }, 500);
            body.classList.add('nav-open');
            this.sidebarVisible = true;
        } else {
            this.toggleButton.classList.remove('toggled');
            this.sidebarVisible = false;
            body.classList.remove('nav-open');
        }
    }
    ngOnDestroy() {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');
        body.classList.remove('off-canvas-sidebar');
    }

    login() {
        if (this.loginForm.valid) {
            this.spinner.show();
            this.loginDTO = new LoginDTO();
            this.loginDTO.EMail = this.loginForm.get('email').value;
            this.loginDTO.Password = this.loginForm.get('password').value;
            this.loginService.logIn(this.loginDTO).subscribe(
                (response: any) => {
                    if (response.code == 200) {
                        const res = <LoginDetailsDTO>response.loginDetailsDTO;
                        this.storageService.saveData('account', res);
                        this.storageService.saveData('token', res.accessToken.replace('"', ''));
                        this.redirect(res);
                    } else {
                        this.toastService.showNotification(response.message);
                    }
                    this.spinner.hide();
                },
                (error: string) => {
                    this.toastService.showNotification(error);
                }
            );
        } else {
            this.loginForm.markAsTouched();
        }

    }

    /**
     * Redirecciona a los usuarios con el rol "Administrador" o "Todos" a la pantalla de selección
     */
    private redirect(user: LoginDetailsDTO) {
        const roles = user.roleIDs;
        if (roles && roles.includes(1)) {
            this.router.navigate(['module-selector']);
        } else {
            this.router.navigate(['dashboard/main-panel']);
        }
    }

    sendEmail() {
        this.usersService.recoverPasswordEmailRequest(this.email).subscribe(
            (response: any) => {
                if (response.code !== 200) {
                    this.toastService.showNotification(response.message);
                }
            },
            (error: string) => {
                this.toastService.showNotification(error);
            }
        );
    }
   
}
