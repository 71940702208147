import { Pipe, PipeTransform } from '@angular/core';
import { ContactDTO } from '../models/contactDTO';

@Pipe({
  name: 'filterContacts'
})
export class FilterContactsPipe implements PipeTransform {

  transform(contacts: ContactDTO[], arg: string): any {
    let founded: ContactDTO[] = [];
    arg = arg.toUpperCase().replace(/ /g, '');
    founded = contacts.filter(c =>
      (c.email && c.email.toUpperCase().replace(/ /g, '').includes(arg)) ||
      (c.name && c.name.toUpperCase().replace(/ /g, '').includes(arg)) ||
      (c.phoneNumber && c.phoneNumber.toUpperCase().replace(/ /g, '').includes(arg)) ||
      (c.occupation && c.occupation.toUpperCase().replace(/ /g, '').includes(arg)) ||
      (c.client.name && c.client.name.toUpperCase().replace(/ /g, '').includes(arg))
    );
    // for(let i = 0; i < contacts.length; i++){
    //   if(contacts[i].email.toUpperCase().replace(/ /g, '').includes(arg) ||
    //     contacts[i].name.toUpperCase().replace(/ /g, '').includes(arg) ||
    //     contacts[i].phoneNumber.replace(/ /g, '').replace('-', '').includes(arg) ||
    //     contacts[i].occupation.toUpperCase().replace(/ /g, '').includes(arg) ||
    //     contacts[i].client.name.toUpperCase().replace(/ /g, '').includes(arg)
    //   ){
    //     founded.push(contacts[i]);
    //   }
    // }
    return founded;
  }
}


