import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthGuard } from './helpers/auth.guard';
import { ModuleSelectorComponent } from './module-selector/module-selector.component';
import { AdminGuard } from './helpers/admin.guard';
import { PortalAdminLayoutComponent } from './layouts/portal-admin/portal-admin-layout.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',

  },
  {
    path: '',
    loadChildren: './pages/pages.module#PagesModule',
  },
  {
    path: 'module-selector',
    component: ModuleSelectorComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'dashboard',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AuthGuard]
      },
      {
        path: 'users-groups',
        loadChildren: './dashboard/users-groups/users-groups.module#UsersGroupsModule',
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'administration',
    component: PortalAdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./portal-administration/portal-administration.module').then(m => m.PortalAdministrationModule),
        canActivate: [AdminGuard]
      }
    ]
  },
  {
    path: 'digital-quotation',
    loadChildren: () => import('./digital-quotation/digital-quotation.module').then(m => m.DigitalQuotationModule)
  }
];

