import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { UrlService } from '../../assets/url-service.service';
import { take } from 'rxjs/operators';
import { CreateDiscountDTO, DiscountDTO, DiscountResponse } from '../models/discountDTO';
import { UserAccountDTO } from '../models/userAccountDTO';
import { ResponseDTO } from '../models/ResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class DiscountsService {

  constructor(private http: HttpClient, private urlService: UrlService) { }

  public async enumDiscountsByClientID(clientID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_DISCOUNTS}/${clientID}`;
    const res = await this.http.get<DiscountResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.discounts;
  }

  public async updateDiscounts(clientID: number, discounts: DiscountDTO[], deleteDiscountsIds: number[]) {
    const account: UserAccountDTO = JSON.parse(localStorage.getItem('account'));
    const myID = account.accountID;
    const body: CreateDiscountDTO = { clientID, discounts, myID, deleteDiscountsIds };
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_DISCOUNTS;
    const res = await this.http.post<ResponseDTO>(url, body).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
  }

}
