import { Component, Input, OnInit } from '@angular/core';
import { IndustryModule } from 'src/app/models/industryModule';

@Component({
  selector: 'app-module-two',
  templateUrl: './module-two.component.html',
  styleUrls: ['./module-two.component.css']
})
export class ModuleTwoComponent implements OnInit {

  @Input() module: IndustryModule;
  @Input() readonly: boolean = false;
  constructor() { }

  ngOnInit() {
  }

  uploadImage(event: any) {
    if (!this.readonly) {
      const target = event.target as HTMLInputElement;
      const file = target.files[0];
      const validExtensions = ['png', 'jpg', 'jpeg'];
      const extension = file.name.split('.').pop().toLocaleLowerCase();
      if (!validExtensions.includes(extension)) {
        //this.toastService.showNotification('Solo se aceptan imagenes .png y .jpg');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        const path = reader.result as string;
        this.module.imagePath1 = path;
      };
      reader.readAsDataURL(file);
    }
  }
}
