import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class UserPresenceService {

  constructor(private api: ApiService) { }

  public keepOnline() {
    this.api.get('API_ACCOUNTS', 'keepOnline');
  }

  public setStatus(online: boolean) {
    // this.api.post
  }
}
