import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UserPresenceService } from './services/ecommerce/user-presence.service';
import { LoginServiceService } from './services/login.service';

@Component({
  selector: 'app-my-app',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  private _router: Subscription;

  constructor(
    private router: Router,
    private userPresence: UserPresenceService,
    private loginService: LoginServiceService
  ) {
  }

  ngOnInit() {
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      this.validateSession();
      const body = document.getElementsByTagName('body')[0];
      const modalBackdrop = document.getElementsByClassName('modal-backdrop')[0];
      if (body.classList.contains('modal-open')) {
        body.classList.remove('modal-open');
        modalBackdrop.remove();
      }
      const mainPanel = document.getElementsByClassName('main-panel')[0];
      if (mainPanel) {
        mainPanel.scroll({ top: 0 });
      }
    });
    this.init();
  }

  // validates session. Used on pageload and in navigation
  private async validateSession() {
    const account = localStorage.getItem('account');
    const token = localStorage.getItem('token');
    if (!account || !token) return false;
    const valid = await this.loginService.validateSession();
    if (!valid) {
      this.loginService.logOut();
      this.router.navigate(['/login']);
    }
    return valid;
  }

  private async init() {
    const account = localStorage.getItem('account');
    const token = localStorage.getItem('token');
    const valid = await this.validateSession();
    if (!valid) return;
    const keepAlive = () => {
      //if (account && token) { this.userPresence.keepOnline(); }
    };
    keepAlive();
    // si existe un usuario logueado, se envía a la API que lo mantenga online
    setInterval(keepAlive, 10000);
  }
}
