import { CountryDTO } from './../models/countryDTO';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { UrlService } from '../../assets/url-service.service';
import { take } from 'rxjs/operators';
import { ProductsReponse } from '../models/productDTO';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient, private urlService: UrlService) { }

  public async getEnabledProducts() {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const res = await this.http.get<ProductsReponse>(urlRes.API_PRODUCTS).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.products;
  }

  massUpload(excel: any) {
    let myID = JSON.parse(localStorage.getItem('account')).accountID;
    let body = {
      excel: excel
    };
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.post(response.API_PRODUCTS + '/' + myID, body, { headers: this.headers }).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        });
    })
  }

  getProductsByCountries(countriesID: CountryDTO[], clientID: number) {
    let header = new HttpHeaders().set('client_id', clientID.toString());
    let body = {
      countries: countriesID
    }

    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.post(response.API_PRODUCTS + "/country/" + clientID, body, { headers: header }).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        }
      )

    });

  }
}
