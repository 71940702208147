import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignalrService } from '../services/socket/signalr.service';
import { UsersService } from '../services/users.service';
import { UserAccountDTO } from '../models/userAccountDTO';

@Component({
  selector: 'app-module-selector',
  templateUrl: './module-selector.component.html',
  styleUrls: ['./module-selector.component.css']
})
export class ModuleSelectorComponent implements OnInit {

  constructor(private router: Router,private socket:SignalrService, private userSevice:UsersService) { }
  my = JSON.parse(localStorage.getItem('account')) as UserAccountDTO;
  ngOnInit() {     
  }
  

  redirect(path: string) {

    if(path==='dashboard/main-panel'){
     this.userSevice.Online(this.my.accountID).subscribe(
      (response: any) => {
        if(response.code == 200){
          console.log(response)
        }});
     
      this.socket.connected();
     }


    this.router.navigate([path]);
  }

}
