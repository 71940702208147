import { Injectable } from '@angular/core';
import { UserAccountDTO } from '../models/userAccountDTO';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
  saveData(key: string, data: any){
    localStorage.setItem(key, JSON.stringify(data));
  }

}
