import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IndustryModule } from 'src/app/models/industryModule';

@Component({
  selector: 'app-module-editor',
  templateUrl: './module-editor.component.html',
  styleUrls: ['./module-editor.component.css']
})
export class ModuleEditorComponent implements OnInit {
  @Input() module: IndustryModule;
  @Input() types: number[];
  @Output() moduleChange = new EventEmitter<IndustryModule>();
  @Output() moveUp = new EventEmitter<IndustryModule>();
  @Output() moveDown = new EventEmitter<IndustryModule>();
  @Output() delete = new EventEmitter<IndustryModule>();
  
  onModuleColorChange(event: any) {
    this.module.moduleColor = event.target.value;
    this.moduleChange.emit(this.module);
  }

  onTextColorChange(event: any) {
    this.module.textColor = event.target.value;
    this.moduleChange.emit(this.module);
  }

  deleteModule() {
    this.delete.emit(this.module);
  }
  moveModuleDown() {
    this.moveDown.emit(this.module);
  }
  moveModuleUp() {
    this.moveUp.emit(this.module);
  }


  constructor() { }

  ngOnInit() {
  }

}
