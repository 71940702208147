import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { UrlService } from '../../assets/url-service.service';

@Injectable({
  providedIn: 'root'
})
export class QuotationItemService {

  constructor(private http: HttpClient, private urlService: UrlService) { }

  enumQuotationItemsByQuotationID(quotationID: number){
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
               this.http.get(response.API_QUOTATION_ITEMS + '/' + quotationID).subscribe(
        (response: any) => {
          observable.next(response);
        },
        (error: any) => {
          observable.error(error.message);
        }
      )
    }); 
        }
      )

  }
}
