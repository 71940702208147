import { CommentDTO } from './../models/commentDTO';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDate'
})
export class FilterDatePipe implements PipeTransform {

  transform(value: CommentDTO[], d1, d2): any {
    var date1 = new Date(d1);
    date1.setDate(date1.getDate());
    var date2 = new Date(d2);
    date2.setDate(date2.getDate()+1);
    var arr = [];
    for(let a = 0; a < value.length; a++){
      if(new Date(value[a].creationDate) >= date1 && new Date(value[a].creationDate) <= date2 ){
        arr.push(value[a]);
      }
    }
    
    var i = 0;
    var cont;
    var u = arr.length-1;
    var aux;
    var min;
    while(i < u){
      min = i;
      cont = min + 1;
      while(cont <= u){
        if(arr[min].creationDate < arr[cont].creationDate){
          min = cont;
        }
        cont++;
      }
      if(min != i){
        aux = arr[i];
        arr[i] = arr[min];
        arr[min] = aux;
      }
      i++;
    }


    return arr;

  }

}
