import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLength'
})
export class MaxLengthPipe implements PipeTransform {

  transform(value: string, arg: number): any {
    if(value.length > arg){
      let truncated = value.slice(0, arg);
      truncated += '...';
      return truncated
    }else{
      return value;
    }
  }

}
