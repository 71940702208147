import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IndustryModule } from 'src/app/models/industryModule';

@Component({
  selector: 'app-module-one',
  templateUrl: './module-one.component.html',
  styleUrls: ['./module-one.component.css']
})
export class ModuleOneComponent implements OnInit {
  @Input() module: IndustryModule;
  @Input() readonly: boolean = false;
  //@Output() moduleChange = new EventEmitter<IndustryModule>();
  
  constructor() { }
  
  ngOnInit() {
  }
  
  /*onBlur() {
    this.moduleChange.emit(this.module);
  }*/
}
