import { Component, Input, OnInit } from '@angular/core';
import { IndustryModule } from 'src/app/models/industryModule';

@Component({
  selector: 'app-module-three',
  templateUrl: './module-three.component.html',
  styleUrls: ['./module-three.component.css']
})
export class ModuleThreeComponent implements OnInit {

  @Input() module: IndustryModule;
  @Input() readonly: boolean = false;
  
  constructor() { }

  ngOnInit() {
  }

  uploadVideo(event: any) {
    if (!this.readonly){
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.module.videoPath = e.target.result; 
        };
        reader.readAsDataURL(file);
      }
    }
  }

}
