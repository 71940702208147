import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ContactDTO, ContactsResponse } from '../models/contactDTO';
import { UrlService } from '../../assets/url-service.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient, private urlService: UrlService) { }

  enumContacts(account_id?) {
    let headers: HttpHeaders;
    if (account_id) {
      headers = new HttpHeaders().set('token', localStorage.getItem('token')).set('account_id', account_id);
    } else {
      headers = new HttpHeaders().set('token', localStorage.getItem('token'));
    }
    return Observable.create(observable => {
      this.urlService.url().subscribe((response: any) => {
        this.http.get(response.API_CONTACTS, { headers: headers }).subscribe(
          (response: any) => { observable.next(response); },
          (error: any) => { observable.error(error.message); }
        );
      });
    });
  }

  public async deleteContact(contactID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_CONTACTS + '/' + contactID;
    return this.http.delete(url).pipe(take(1)).toPromise();
  }

  getContactByID(contactID: number) {
    return Observable.create(observable => {
      this.urlService.url().subscribe((response: any) => {
        this.http.get(response.API_CONTACTS + '/' + contactID).subscribe(
          (response: any) => { observable.next(response); },
          (error: any) => { observable.error(error.message); }
        );
      });
    });
  }

  createContact(contact: ContactDTO) {
    return Observable.create(observable => {
      this.urlService.url().subscribe((response: any) => {
        this.http.post(response.API_CONTACTS, contact, { headers: this.headers }).subscribe(
          (response: any) => { observable.next(response); },
          (error: any) => { observable.error(error.message); }
        );
      });
    });
  }

  updateContact(contact: ContactDTO) {
    return Observable.create(observable => {
      this.urlService.url().subscribe((response: any) => {
        this.http.put(response.API_CONTACTS + '/' + contact.contactID, contact, { headers: this.headers }).subscribe(
          (response: any) => { observable.next(response); },
          (error: any) => { observable.error(error.message); }
        );
      });
    });
  }

  public async enumContactsByClient(clientID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_CONTACTS + '/getbyclient/' + clientID;
    const res = await this.http.get<ContactsResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.contacts;
  }
}
