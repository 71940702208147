import { Pipe, PipeTransform } from '@angular/core';
import { UserAccountDTO } from '../models/userAccountDTO';

@Pipe({
  name: 'filterUserAccount'
})
export class FilterAccPipe implements PipeTransform {

  transform(value: UserAccountDTO[], arg: string): any {
    const resultData = [];
    arg = arg.toUpperCase().replace(/ /g,'');
    
    for(let i = 0; i < value.length; i++){
      var flag = 0;
      let name = value[i].firstName + value[i].lastName;
      name = name.toUpperCase().replace(/ /g,'');
      if(value[i].groups.length > 0){
      for(let g = 0; g < value[i].groups.length; g++){
        if(value[i].groups[g].description.toUpperCase().replace(/ /g,'').includes(arg)){
          flag = 1;
        }
      }}
      if(name.includes(arg) || value[i].eMail.toUpperCase().replace(/ /g,'').includes(arg) || flag == 1){
         
         resultData.push(value[i]);
    }
  }

    return resultData;
  }

}
