import { Pipe, PipeTransform } from '@angular/core';
import { ProductDTO } from '../models/productDTO';

@Pipe({
  name: 'filterProducts'
})
export class FilterProductsPipe implements PipeTransform {

  transform(value: ProductDTO[], arg: any): any {
    const products: ProductDTO[] = [];
    arg = arg.toString().toUpperCase().replace(/ /g, '');
    for(let i = 0; i < value.length; i++){
      if(value[i].description.toUpperCase().replace(/ /g, '').includes(arg) ||
        value[i].listPrice.toString().toUpperCase().replace(/ /g, '').includes(arg) ||
        value[i].partNumber.toString().toUpperCase().replace(/ /g, '').includes(arg)
      ){
        products.push(value[i]);
      }
    }
    return products;





    
  }

}
