import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserAccountDTO } from '../models/userAccountDTO';
import { EditBasicAccountDataDTO } from '../models/editBasicAccountDataDTO';
import { CreateUserDTO } from '../models/createUserDTO';
import { UrlService } from '../../assets/url-service.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private urlService: UrlService) { }


  getMyGroup() {
    const header = new HttpHeaders().set('token', localStorage.getItem('token'));
    return Observable.create(observer => {
      this.urlService.url().subscribe((res: any) => {
        this.http.get(res.API_ACCOUNTS + '/my-group', { headers: header }).subscribe(
          (response: any) => observer.next(response),
          (error: any) => observer.error(error.message)
        );
      });
    });
  }

  getAllUsers() {
    return Observable.create(observer => {
      this.urlService.url().subscribe((res: any) => {
        this.http.get(res.API_ACCOUNTS).subscribe(
          (response: any) => observer.next(response),
          (error: any) => observer.error(error.message)
        );
      });
    });
  }

  getAllSalesManagers() {
    return Observable.create(observer => {
      this.urlService.url().subscribe((res: any) => {
        this.http.get(res.API_ACCOUNTS + '/sales-manager').subscribe(
          (response: any) => observer.next(response),
          (error: any) => observer.error(error.message)
        );
      });
    });
  }

  editBasicAccountData(account: EditBasicAccountDataDTO) {
    account.myID = JSON.parse(localStorage.getItem('account')).accountID;
    return Observable.create(observer => {
      this.urlService.url().subscribe((res: any) => {
        this.http.put(res.API_ACCOUNTS + '/' + account.accountID, account, { headers: this.headers }).subscribe(
          response => observer.next(response),
          error => observer.error(error.message)
        );
      });
    });
  }

  createUserAccount(account: CreateUserDTO) {
    account.myID = JSON.parse(localStorage.getItem('account')).accountID;
    return Observable.create(observer => {
      this.urlService.url().subscribe((res: any) => {
        this.http.post(res.API_ACCOUNTS, account, { headers: this.headers }).subscribe(
          response => observer.next(response),
          error => observer.error(error.message)
        );
      });
    });
  }

  activateAccount(activationKey: string) {
    const body = { key: activationKey };
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.put(res.API_ACTIVATEACCOUNT, body, { headers: this.headers }).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  changePassword(accountID: number, oldPassword: string, newPassword: string) {
    const body = {
      oldPassword: oldPassword,
      newPassword: newPassword
    };
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.put(res.API_ACCOUNTS + '/password/' + accountID, body, { headers: this.headers }).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  recoverPassword(accountID: number, activationKey: string, password: string) {
    const body = {
      accountID: accountID,
      activationKey: activationKey,
      password: password
    };
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.put(res.API_ACCOUNTS + '/recover-password', body, { headers: this.headers }).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  recoverPasswordEmailRequest(email: string) {
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.get(res.API_ACCOUNTS + '/recover-password/' + email).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  deleteAccount(myID: number, accountID: number) {


    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.delete(res.API_ACCOUNTS + '/' + myID + '/' + accountID).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  suspendAccount(accountDTO: UserAccountDTO) {
    accountDTO.myID = JSON.parse(localStorage.getItem('account')).accountID;
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.put(res.API_ACCOUNTS + '/suspend-account', accountDTO, { headers: this.headers }).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  removeSuspendAccount(accountDTO: UserAccountDTO) {
    accountDTO.myID = JSON.parse(localStorage.getItem('account')).accountID;
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.put(res.API_ACCOUNTS + '/remove-suspend-account', accountDTO, { headers: this.headers }).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  setPasswordByAdmin(accountID, password) {
    const body = {
      accountID: accountID,
      password: password,
      myID: JSON.parse(localStorage.getItem('account')).accountID
    };
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.patch(res.API_ACCOUNTS + '/' + accountID, body, { headers: this.headers }).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  getImg() {
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        this.http.get(res.API_ACCOUNTS + '/' + JSON.parse(localStorage.getItem('account')).accountID).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  setImg(img64: string) {
    const body = { image64: img64 };
    return Observable.create(observable => {
      this.urlService.url().subscribe((res: any) => {
        const url = res.API_ACCOUNTS + '/set-img/' + JSON.parse(localStorage.getItem('account')).accountID;
        this.http.put(url, body, { headers: this.headers }).subscribe(
          response => observable.next(response),
          error => observable.error(error.message)
        );
      });
    });
  }

  Online(id:number) {
    const header = new HttpHeaders().set('token', localStorage.getItem('token'));
    return Observable.create(observer => {
      this.urlService.url().subscribe((res: any) => {
        this.http.get(res.API_ACCOUNTS + '/online/'+id, { headers: header }).subscribe(
          (response: any) => observer.next(response),
          (error: any) => observer.error(error.message)
        );
      });
    });
  }

  Offline(id: number): void {
    this.urlService.url().subscribe((res: any) => {
        const url = res.API_ACCOUNTS + '/offline/' + id;
        navigator.sendBeacon(url, null);
    });
  }
}
