import { CommentDTO } from './../models/commentDTO';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortComment'
})
export class SortCommentPipe implements PipeTransform {

  transform(comments: CommentDTO[], args?: any): any {
    return comments.sort((a, b) => {
      const dateA = new Date(a.creationDate);
      const dateB = new Date(b.creationDate);
      return dateB.getTime() - dateA.getTime();
    });
  }

}
