import { Pipe, PipeTransform } from '@angular/core';
import { GeneralParameterDTO } from '../models/general-parameterDTO';

@Pipe({
  name: 'filterGeneralParameter'
})
export class FilterGeneralParameterPipe implements PipeTransform {

  transform(value: GeneralParameterDTO[], arg: string): any {
    const data: GeneralParameterDTO[] = [];
    arg = arg.toUpperCase().replace(/ /g, '');
    for(let i = 0; i < value.length; i++){
      if(value[i].parameterName.toUpperCase().replace(/ /g, '').includes(arg) ||
        value[i].value.toUpperCase().replace(/ /g, '').includes(arg)){
          data.push(value[i]);
        }
    }
    return data;
  }

}
