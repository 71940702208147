import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnection,HubConnectionBuilder} from '@aspnet/signalr'
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SignalrService {

  private hubConnection: HubConnection;

  public onlineStatus$ = new Subject<string>();
  public offlineStatus$ = new Subject<string>();
  private apiSocket=environment.apiSocket
  constructor(private http:HttpClient) {
    
  }

  connected(){
    this.http.post(this.apiSocket+"online/userOnline","").subscribe(resp=>{console.log(resp)})
  }
  desconnected(){
    this.http.post(this.apiSocket+"online/userOffline","").subscribe(resp=>{console.log(resp)})
  }
  messages(){
    this.http.post(this.apiSocket+"online/message","").subscribe(resp=>{console.log(resp)})
  }
}
