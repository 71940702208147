import { Pipe, PipeTransform } from '@angular/core';
import { QuotationDTO } from '../../models/quotationDTO';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(value: QuotationDTO[]): any {
    
    

       
    var min = 0;
    var start = 0;
    var cont = 0;

    while(start < value.length){
      min = start;
      cont = min + 1;

      while(cont < value.length){
        if(value[min].creationDate < value[cont].creationDate){
          min = cont;
        }
        if(min != start){
          let aux = value[min];
          value[min] = value[start];
          value[start] = aux;
        }
        cont ++;
      }

      start++;
    }

    return value;

  }

}
