import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { GeneralParameterDTO } from '../models/general-parameterDTO';
import { UrlService } from '../../assets/url-service.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralParametersService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient, private urlService: UrlService) { }

  getGeneralParameters(){
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
      this.http.get(response.API_GENERAL_PARAMETERS).subscribe(
        (response: any) => {
          observable.next(response);
        },
        (error: any) => {
          observable.error(error.message);
        }
      )
    });})
  }

  setGeneralParameters(generalParameter: GeneralParameterDTO){
    
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
      this.http.put(response.API_GENERAL_PARAMETERS, generalParameter, {headers: this.headers}).subscribe(
        (response: any) => {
          observable.next(response);
        },
        (error: any) => {
          observable.error(error.message);
        }
      )
    });})
  }

  setGoals(){
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
      this.http.get(response.API_GENERAL_PARAMETERS + '/metrics').subscribe(
        (response: any) => {
          observable.next(response);
        },
        (error: any) => {
          observable.error(error.message);
        }
      )
    });})
  }
}
