import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IndustryModule } from 'src/app/models/industryModule';

@Component({
  selector: 'app-standard-module',
  templateUrl: './standard-module.component.html',
  styleUrls: ['./standard-module.component.css']
})
export class StandardModuleComponent implements OnInit, OnChanges {

  @Input() industryId: number;
  @Input() modules: IndustryModule[] = [];
  filteredModules: IndustryModule[] = [];
  @Output() modulesChange = new EventEmitter<IndustryModule[]>();

  selectedType: number;
  types: number[] = [1, 2, 3];

  constructor() {  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modules) {
      // Ejecuta la actualización cuando cambie la lista de módulos
      this.updateFilteredModules();
    }
  }

  onDelete(id: number) {
    const index = this.modules.findIndex(mod => mod.moduleId === id);

    if (index !== -1 && this.modules[index].moduleId !== 0) {
      // Realizar el borrado lógico marcando el módulo como eliminado
      this.modules[index].isDeleted = true;

      // Ajustar el 'order' de los módulos siguientes
      for (let i = index + 1; i < this.modules.length; i++) {
        if (!this.modules[i].isDeleted) {
          this.modules[i].order--;
        }
      }
    }
    this.updateFilteredModules();
  }
  
  onMoveUp(id: number) {
    const index = this.modules.findIndex(mod => mod.moduleId === id);
    
    if (index > 0) {
      // Intercambia el módulo con el módulo anterior
      [this.modules[index], this.modules[index - 1]] = [this.modules[index - 1], this.modules[index]];
      
      // Ajusta los valores de 'order'
      this.modules[index].order++;
      this.modules[index - 1].order--;
    }

    this.updateFilteredModules();
  }
  
  onMoveDown(id: number) {
    const index = this.modules.findIndex(mod => mod.moduleId === id);
    
    if (index < this.modules.length - 1) {
      // Intercambia el módulo con el siguiente módulo
      [this.modules[index], this.modules[index + 1]] = [this.modules[index + 1], this.modules[index]];
      
      // Ajusta los valores de 'order'
      this.modules[index].order--;
      this.modules[index + 1].order++;
    }
    
    this.updateFilteredModules();
  }
  
  updateModule(updatedModule: IndustryModule) {
    const index = this.modules.findIndex(mod => mod.moduleId === updatedModule.moduleId);
    
    if (index !== -1) {
      this.modules[index] = { ...this.modules[index], ...updatedModule };
    }
    
    this.updateFilteredModules();
  }
  
  addNew(newModule: IndustryModule) {
    newModule.order = this.modules.length;
    this.modules.push(newModule);
    this.updateFilteredModules();
  }

  private updateFilteredModules() {
    this.filteredModules = this.modules.filter(m => !m.isDeleted);
  }
}
