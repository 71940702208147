import { DiscountDTO } from './../models/discountDTO';
import { QuotationDTO } from './../models/quotationDTO';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { UrlService } from '../../assets/url-service.service';
import { DigitalQuotationResponse, QuotationExpiredTrackingResponse, QuotationUnreadMessagesResponse } from '../models/digital-quotation';
import { DigitalQuotationMessagesResponse, QuotationMessage } from '../models/digital-quotation-messages';
import { ResponseDTO } from '../models/ResponseDTO';
import { ApiService } from './api.service';
import { QuotationHistoryResponse } from '../models/quotation-history';
import { UserAccountDTO } from '../models/userAccountDTO';

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {

  headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  myID = JSON.parse(localStorage.getItem('account')).accountID;
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private api: ApiService
  ) {
    this.myID = JSON.parse(localStorage.getItem('account')).accountID;
  }

  getQuotations() {
    let headers = new HttpHeaders().set('id', JSON.parse(localStorage.getItem('account')).accountID);
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.get(response.API_QUOTATIONS, { headers: headers }).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        });
    })
  }
  getQuotationsPage(page:number,filter:string, accountId:number) {
    let headers = new HttpHeaders().set('id', JSON.parse(localStorage.getItem('account')).accountID);
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.get(response.API_QUOTATIONS+'?page='+page+'&filter='+filter+'&accountId='+accountId, { headers: headers }).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        });
    })
  }

  soldQuotation(quotation: QuotationDTO) {
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.put(response.API_QUOTATIONS + '/sold/' + this.myID, quotation).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        });
    })
  }

  notSoldQuotation(quotation: QuotationDTO, comment: string) {
    let headers = new HttpHeaders().set('comment', comment);
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.put(response.API_QUOTATIONS + '/notsold/' + this.myID, quotation, { headers: headers }).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        });
    })
  }

  getQuotationByID(quotationID: number) {

    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.get(response.API_QUOTATIONS + '/' + quotationID).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        });
    })
  }

  public async createQuotation(
    quotations, quotationItems, accountID: number[], comment: string, discounts: DiscountDTO[], attachments: File[]
  ) {
    quotations.accountID = this.myID;
    const body = {
      quotations: quotations,
      quotationItems: quotationItems,
      accountID: accountID,
      comment: comment,
      discounts
    };
    const formData = new FormData();
    formData.append('quotation', JSON.stringify(body));
    attachments.forEach(file => {
      formData.append('attachments', file);
    });
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_QUOTATIONS + '/form/' + this.myID;
    const res = await this.http.post<ResponseDTO>(url, formData).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.message;
  }

  getPdf(quotationID: number) {
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.get(response.API_QUOTATIONS + '/pdf/' + quotationID + '/' + this.myID).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        });
    })
  }

  getMetrics(accounts: number[], start: Date, end: Date, admin?): Observable<any> {
    this.myID = JSON.parse(localStorage.getItem('account')).accountID;
    let body = {
      accountId: this.myID,
      accounts: accounts,
      start: start,
      end: end
    }

    let headers = new HttpHeaders().set('table', '0');
    if (admin > 0) {
      headers.set('admin', admin);
    }
    return new Observable(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.post(response.API_QUOTATIONS + '/metrics', body, { headers: headers }).subscribe(
            (response: any) => {
              console.log(response)
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        }
      )
    })
  }

  getPendingQuotations(id: number) {
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.get(response.API_QUOTATIONS + '/pending-quotations/' + id).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        }
      )
    })
  }

  createMetrics(metrics) {
    let body = {
      metric: metrics
    }

    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.post(response.API_QUOTATIONS + '/metrics/create', body).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          )
        }
      )
    })
  }

  public async getDigitalQuotation(quotationId: number) {
    const headers = new HttpHeaders().set('token', localStorage.getItem('token'));
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_QUOTATIONS}/digitalQuotation/seller/${quotationId}`;
    const res = await this.http.get<DigitalQuotationResponse>(url, { headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.digitalQuotation;
  }

  public async getDigitalQuotationMessages(quotationId: number) {
    const headers = new HttpHeaders().set('token', localStorage.getItem('token').replace(/"/g, ''));
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_QUOTATIONS}/digitalQuotation/message?quotationId=${quotationId}`;
    const res = await this.http.get<DigitalQuotationMessagesResponse>(url, { headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.messages;
  }

  public async postDigitalQuotationMessage(quotationId: number, text: string, files: File[]) {
    const formData = new FormData();
    files.forEach(file => formData.append('messageFiles', file));
    const message: QuotationMessage = { quotationId, message: text, token: '' };
    formData.append('message', JSON.stringify(message));
    const res = await this.api.postForm<ResponseDTO>('API_BASE', formData, 'quotations/digitalQuotation/message');
    if (res.code != 200) { throw new Error(res.message); }
    return res.message;
  }

  public async getHistory(quotationId: number) {
    const headers = new HttpHeaders().set('token', localStorage.getItem('token').replace(/"/g, ''));
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_QUOTATIONS}/digitalQuotation/interactions/${quotationId}`;
    const res = await this.http.get<QuotationHistoryResponse>(url, { headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.interactions;
  }

  public async getClientName(quotationId: number) {
    const headers = new HttpHeaders().set('token', localStorage.getItem('token').replace(/"/g, ''));
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_QUOTATIONS}/clientName/${quotationId}`;
    const res = await this.http.get<ResponseDTO>(url, { headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.message;
  }

  public async getUnreadMessages(accountId: number) {
    const headers = new HttpHeaders().set('token', localStorage.getItem('token').replace(/"/g, ''));
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_QUOTATIONS}/digitalQuotation/unreadMessages/${accountId}`;
    const res = await this.http.get<QuotationUnreadMessagesResponse>(url, { headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.quotationsWithUnreadInteractions;
  }

  public async getExpiredTracking(accountId: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_QUOTATIONS}/expiredTrackings/${accountId}`;
    const res = await this.http.get<QuotationExpiredTrackingResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.expiredTrackings;
  }

}
