import { Pipe, PipeTransform } from '@angular/core';
import { DiscountDTO } from '../../models/discountDTO';
import { CountryDTO } from '../../models/countryDTO';

@Pipe({
  name: 'discountCountries'
})
export class DiscountCountriesPipe implements PipeTransform {

  transform(discounts: DiscountDTO[], country: CountryDTO): any {
    return discounts.filter(d => !country || !country.countryID || d.country.countryID == country.countryID);
  }

}
