import { SampleService } from './services/sample.service';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { FixedpluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';

import { AppRoutes } from './app.routing';
import { AuthGuard } from './helpers/auth.guard';
import { NoIdentityGuard } from './helpers/no-identity.guard';
import { LoginComponent } from './pages/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { UsersService } from './services/users.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FilterAccPipe } from './pipes/filter.pipe';
import { PipesModule } from './pipes/pipes.module';
import { ClientsService } from './services/clients.service';
import { DiscountsService } from './services/discounts.service';
import { ProductService } from './services/product.service';
import { ContactService } from './services/contact.service';
import { NavbarModule } from './navbar/navbar.module';
import { GeneralParametersService } from './services/general-parameters.service';
import { ToastService } from './services/components/toast.service';
import { HasPermissionService } from './services/has-permission.service';
import { CountriesService } from './services/countries.service';
import { QuotationsService } from './services/quotations.service';
import { QuotationItemService } from './services/quotation-item.service';
import { UrlService } from '../assets/url-service.service';
import { CommentService } from './services/comment.service';
import { GaugeChartModule } from 'angular-gauge-chart';
import { ModuleSelectorComponent } from './module-selector/module-selector.component';

import { PdfMakeWrapper } from 'pdfmake-wrapper';
import * as pdfFonts from 'pdfmake/build/vfs_fonts'; // fonts provided for pdfmake
import { PortalAdminLayoutComponent } from './layouts/portal-admin/portal-admin-layout.component';
import { SidebarPortalModule } from './sidebar-portal/sidebar-portal.module';
import { StandardModuleModule } from './shared/standard-module/standard-module.module';

// If any issue using previous fonts import. you can try this:
// import pdfFonts from "pdfmake/build/vfs_fonts";

// Set the fonts to use
PdfMakeWrapper.setFonts(pdfFonts);


@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    StandardModuleModule
  ],
  declarations: [ModuleSelectorComponent],

})
export class MaterialModule { }

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true, scrollPositionRestoration: 'top'
    }),
    HttpModule,
    MaterialModule,
    MatNativeDateModule,
    SidebarModule,
    SidebarPortalModule,
    FooterModule,
    FixedpluginModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    PipesModule,
    NavbarModule,
    GaugeChartModule

  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    PortalAdminLayoutComponent,
  ],
  bootstrap: [AppComponent],
  providers: [AuthGuard,
    NoIdentityGuard,
    LoginComponent,
    UsersService,
    FilterAccPipe,
    ClientsService,
    DiscountsService,
    ProductService,
    ContactService,
    GeneralParametersService,
    ToastService,
    HasPermissionService,
    CountriesService,
    QuotationsService,
    QuotationItemService,
    UrlService,
    CommentService,
    SampleService
  ]
})
export class AppModule { }
