import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CommentDTO, TrackingCommentDTO } from '../models/commentDTO';
import { UrlService } from '../../assets/url-service.service';
import { take } from 'rxjs/operators';
import { Client, ClientFilesResponse, ClientResponse, ClientsManagementResponse } from '../models/clients';
import { UserAccountDTO } from '../models/userAccountDTO';
import { ResponseDTO } from '../models/ResponseDTO';
import { Plant, PlantsResponse } from '../models/plants';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  public plantChanged$ = new BehaviorSubject<number>(0);
  public plantCreated = new BehaviorSubject<string>('');

  constructor(private http: HttpClient, private urlService: UrlService) { }

  enumClients() {
    const header = new HttpHeaders().set('token', localStorage.getItem('token'));
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.get(response.API_CLIENTS, { headers: header }).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          );
        });
    });
  }

  enumClientsChat() {
    const header = new HttpHeaders().set('token', localStorage.getItem('token'));
    return new Observable(obs => {
      this.urlService.url().subscribe((response: any) => {
        this.http.get(`${response.API_CLIENTS}/industry`, { headers: header }).subscribe(
          res => obs.next(res),
          err => obs.error(err)
        );
      });
    });
  }

  enumClientsByAccount(account_id) {
    const header = new HttpHeaders().set('token', localStorage.getItem('token')).set('account_id', account_id);
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.get(`${response.API_CLIENTS}/my-group`, { headers: header }).subscribe(
            (resp: any) => {
              observable.next(resp);
            },
            (error: any) => {
              observable.error(error.message);
            }
          );
        });
    });
  }

  deleteClient(clientID: number) {
    const url = '/' + JSON.parse(localStorage.getItem('account')).accountID + '/' + clientID;
    console.log('url: ' + url);
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.delete(response.API_CLIENTS + url, { headers: this.headers }).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
          );
        });
    });
  }

  public async getClientByID(clientID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_CLIENTS}/${clientID}`;
    const res = await this.http.get<ClientResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.client;
  }

  createComment(comment: FormData) {
    return Observable.create(observable => {
      this.urlService.url().subscribe((response: any) => {
        this.http.post(response.API_CLIENTS + '/' + 'comment', comment).subscribe(
          (response: any) => observable.next(response),
          (error: any) => observable.error(error.message)
        );
      });
    });
  }

  async updateTracking(comment: TrackingCommentDTO, file?: File) {
    const formdata = new FormData();
    formdata.append('trackingComment', JSON.stringify(comment));
    if (file) { formdata.append('commentFiles', file); }

    const res = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${res.API_CLIENTS}/trackingcomment`;
    return this.http.post<any>(url, formdata).pipe(take(1)).toPromise();
  }

  public async updateComment(comment: CommentDTO, file?: File) {
    const formdata = new FormData();
    if (file) {
      comment.deleteFiles = false;
      formdata.append('commentFiles', file);
    }
    formdata.append('comment', JSON.stringify(comment));

    const res = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${res.API_COMMENT}${comment.commentID}`;
    return this.http.put<any>(url, formdata).pipe(take(1)).toPromise();
  }

  public async createClient(client: Client) {
    const account: UserAccountDTO = JSON.parse(localStorage.getItem('account'));
    client.myID = account.accountID;
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_CLIENTS;
    const res = await this.http.post<ResponseDTO>(url, client, { headers: this.headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.id;
  }

  public async updateClient(client: Client) {
    const account: UserAccountDTO = JSON.parse(localStorage.getItem('account'));
    client.myID = account.accountID;
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_CLIENTS;
    const res = await this.http.put<ResponseDTO>(url, client, { headers: this.headers }).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
  }

  public async getFiles(clientID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_CLIENTS + '/files/' + clientID;
    const res = await this.http.get<ClientFilesResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) throw new Error(res.message);
    return res.files;
  }

  public async getClientsManagement(accountsIds: number[]) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_CLIENTS + '/management?' + accountsIds.map(id => 'ids=' + id).join('&');
    const res = await this.http.get<ClientsManagementResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) throw new Error(res.message);
    return res.clients;
  }

  public async getClientPlants(clientID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_BASE + '/plants/client/' + clientID;
    const res = await this.http.get<PlantsResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) throw new Error(res.message);
    return res.plants;
  }

  public async createPlant(plant: Plant) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_BASE + '/plants';
    const res = await this.http.post<ResponseDTO>(url, plant).pipe(take(1)).toPromise();
    if (res.code != 200) throw new Error(res.message);
    this.plantCreated.next('');
  }

  public async updatePlant(plant: Plant) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_BASE + '/plants/' + plant.plantId;
    const res = await this.http.put<ResponseDTO>(url, plant).pipe(take(1)).toPromise();
    if (res.code != 200) throw new Error(res.message);
  }

  public async deletePlant(plantID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_BASE + '/plants/' + plantID;
    const res = await this.http.delete<ResponseDTO>(url).pipe(take(1)).toPromise();
    if (res.code != 200) throw new Error(res.message);
  }

  public selectPlant(plantId: number) {
    this.plantChanged$.next(plantId);
  }
}
