import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardModuleComponent } from './standard-module.component';
import { MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { FormsModule } from '@angular/forms';
import { ModuleOneComponent } from './moduleTypes/module-one/module-one.component';
import { ModuleEditorComponent } from './module-editor/module-editor.component';
import { ModuleAddComponent } from './module-add/module-add.component';
import { ModuleTwoComponent } from './moduleTypes/module-two/module-two.component';
import { ModuleThreeComponent } from './moduleTypes/module-three/module-three.component';

@NgModule({
  declarations: [StandardModuleComponent, ModuleOneComponent, ModuleEditorComponent, ModuleAddComponent, ModuleTwoComponent, ModuleThreeComponent],
  imports: [
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule  
  ],
  exports: [
    StandardModuleComponent,
    ModuleOneComponent,
    ModuleTwoComponent,
    ModuleThreeComponent
  ]
})
export class StandardModuleModule { }
