import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundUp'
})
export class RoundUpPipe implements PipeTransform {

  transform(value: number): any {
    if(1 > Math.round(value)){
      return 1;
    }else{
      return Math.round(value);
    }
  }

}
