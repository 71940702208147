import { Pipe, PipeTransform } from '@angular/core';
import { Chat } from '../models/chat';

@Pipe({
  name: 'filterChats'
})
export class FilterChatsPipe implements PipeTransform {

  transform(value: Chat[], arg: string): Chat[] {
    if (!arg.trim()) {
      return value;
    }
    arg = arg.toUpperCase().replace(/ /g, '');
    return value.filter(chat =>
      chat.name.toUpperCase().replace(/ /g, '').includes(arg) ||
      chat.legalName.toUpperCase().replace(/ /g, '').includes(arg) ||
      chat.email.toUpperCase().replace(/ /g, '').includes(arg) ||
      chat.state.toUpperCase().replace(/ /g, '').includes(arg)
    );
  }

}
