import { Pipe, PipeTransform } from '@angular/core';
import { ClientDTO } from '../models/ClientDTO';

@Pipe({
  name: 'filterClients'
})
export class FilterClientsPipe implements PipeTransform {

  transform(value: ClientDTO[], arg: string): ClientDTO[] {
    let response: ClientDTO[] = [];
    if (arg == null || arg.replace(' ', '') == '') {
      return value;
    }
    arg = arg.toUpperCase().replace(/ /g, '');

    response = value.filter(val =>
      (val.address && val.address.toUpperCase().replace(/ /g, '').includes(arg)) ||
      (val.market && val.market.toUpperCase().replace(/ /g, '').includes(arg)) ||
      (val.name && val.name.toUpperCase().replace(/ /g, '').includes(arg)) ||
      (val.phoneNumber && val.phoneNumber.toUpperCase().replace(/ /g, '').includes(arg))
    );

    return response;
  }

}
