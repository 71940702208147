import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { LoginServiceService } from '../services/login.service';
import { Router } from '@angular/router';
import { PermissionsService } from '../services/permissions.service';
import { ToastService } from '../services/components/toast.service';
import { HasPermissionService } from '../services/has-permission.service';
import { UsersService } from '../services/users.service';
import { LoginDetailsDTO } from '../models/loginDetailsDTO';
import { ChatService } from '../services/ecommerce/chat.service';

declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}


@Component({
    selector: 'app-sidebar-portal-cmp',
    templateUrl: 'sidebar-portal.component.html',
    styleUrls: ['./sidebar-portal.component.scss']
})

export class SidebarPortalComponent implements OnInit {
    chieff = false;
    public menuItems: any[];
    ps: any;
    name: string;
    urlImg: string;

    public hasUnreadMessages: boolean;

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }

    constructor(private loginService: LoginServiceService,
        private router: Router,
        private userService: UsersService,
        private toastService: ToastService
    ) {

    }
    ngOnInit() {
        this.getImg();
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

        const account = JSON.parse(localStorage.getItem('account')) as LoginDetailsDTO;
        this.name = account.firstName + ' ' + account.lastName;
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logOut() {
        this.loginService.logOut();
        this.router.navigate(['/login']);
    }

    getImg() {
        this.userService.getImg().subscribe(
            (response: any) => {
                this.urlImg = response.message;
            },
            (error: string) => {
                this.toastService.showNotification(error);
            }
        );
    }
}
