import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { observable, Observable } from 'rxjs';
import { UrlService } from '../../assets/url-service.service';
import { take } from 'rxjs/operators';
import { CommentResponse } from '../models/commentDTO';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient, private urlService: UrlService) { }

  public async enumByClientID(clientID: number) {
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = `${urlRes.API_COMMENT}client/${clientID}`;
    const res = await this.http.get<CommentResponse>(url).pipe(take(1)).toPromise();
    if (res.code != 200) { throw new Error(res.message); }
    return res.comments;
  }

  enumByAccountID(accountID: number) {
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (resp: any) => {
          if (resp) {
            const url = resp.API_COMMENT + 'account/' + accountID;
            this.http.get(url).subscribe(
              (response: any) => {
                if (response) {
                  observable.next(response);
                } else {
                  observable.next({ message: 'No hay respuesta del servidor' });
                }
              }
            );
          } else {
            observable.next({ message: 'No hay respuesta del servidor' });
          }
        }

      );
    }
    );
  }


  enumComments() {
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (resp: any) => {
          if (resp) {
            const url = resp.API_COMMENT;
            this.http.get(url).subscribe(
              (response: any) => {
                if (response) {
                  observable.next(response);
                } else {
                  observable.next({ message: 'No hay respuesta del servidor' });
                }
              }
            );
          } else {
            observable.next({ message: 'No hay respuesta del servidor' });
          }
        }

      );
    }
    );
  }

}
