import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToWord'
})
export class NumberToWordPipe implements PipeTransform {

  transform(value: number): string {
    let status;
    if(value == 5 || value == 2){
      status = 'En Curso';
    }else if(value == 4){
      status = 'No Compró';
    }else if(value == 3){
      status = 'Compró';
    }else{
      status = 'Pendiente';
    }
    return status;
  }

}
