import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IndustryModule } from 'src/app/models/industryModule';

@Component({
  selector: 'app-module-add',
  templateUrl: './module-add.component.html',
  styleUrls: ['./module-add.component.css']
})
export class ModuleAddComponent implements OnInit, OnChanges {
  
  @Input() position: number;
  @Input() industryId: number;
  @Output() add = new EventEmitter<IndustryModule>();
  
  constructor() {
    console.log(this.industryId);
   }
  
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.industryId) {
      console.log('IndustryId updated:', changes.industryId.currentValue);
      this.industryId = changes.industryId.currentValue;
    }
  }
  
  addModule() {
    console.log('Using IndustryId:', this.industryId);
    const newModule: IndustryModule = {
      moduleId: 0,
      type: 1,
      textColor: '#000000',
      moduleColor: '#ffffff',
      order: this.position,
      isDeleted: false,
      industryId: this.industryId
    };

    this.add.emit(newModule);
  }
}
