import { Injectable } from '@angular/core';
import { Mail, MailListResponse, MailResponse, UnreadMailsCountResponse } from 'src/app/models/mails';
import { ResponseDTO } from 'src/app/models/ResponseDTO';
import { ApiService } from '../api.service';
import { PdfMakeWrapper, Txt, Canvas, Line } from 'pdfmake-wrapper';
import { ECommerceFilter } from 'src/app/models/ECommerceFilter';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  private audio: HTMLAudioElement;
  constructor(private api: ApiService) {
    this.audio = new Audio();
    this.audio.src = '../../assets/sound/sond.mp3';
   }

  public convertToPDF(mail: Mail) {
    const date = mail.dateReceived.toString().split('T')[0];
    const pdf = new PdfMakeWrapper();
    pdf.add(new Txt('Fecha: ' + date).bold().end);
    pdf.add(new Txt(`De: ${mail.name} (${mail.email})`).bold().end);
    pdf.add(new Txt('Industria: ' + mail.industryName).bold().end);
    const subject = new Txt('Asunto: ' + mail.subject).fontSize(18).bold().end;
    pdf.add(subject);
    pdf.add(mail.message);

    if (mail.replied) {
      pdf.add('\n\n');
      const replyDate = mail.dateReplied.toString().split('T')[0];
      pdf.add(new Txt('Fecha: ' + replyDate).bold().end);
      pdf.add(new Txt('Respuesta').fontSize(18).bold().end);
      pdf.add(mail.reply);
    }

    pdf.create().download(mail.subject + ' ' + date);
    return;
  }

  public async getUnreadMails(accountId: number) {
    const res = await this.api.get<UnreadMailsCountResponse>('API_BASE', 'mails/user/' + accountId);
    if (res.code === 200) {
      if(res.mailsNotRead.mailsNotRead>0){
        if(res.mailsNotRead.mailsNotRead!=Number(localStorage.getItem("mails")))
        this.audio.play();     
      }
      localStorage.setItem("mails",res.mailsNotRead.mailsNotRead.toString())
      return res.mailsNotRead;
    } else {
      throw new Error(res.message);
    }
  }

  public async getAll(id: number, filter: ECommerceFilter) {
    const res = await this.api.post<MailListResponse>('API_MAILS', filter, `${id}`);
    if (res.code === 0 || res.code === 200) {
      return res.mails;
    } else {
      throw new Error(res.message);
    }
  }

  public async getById(id: number) {
    const res = await this.api.get<MailResponse>('API_MAILS', id.toString());
    if (res.code === 0 || res.code === 200) {
      return res.mail;
    } else {
      throw new Error(res.message);
    }
  }

  public async setRead(id: number) {
    const res = await this.api.put<ResponseDTO>('API_MAILS', {}, id.toString());
    if (res.code === 0 || res.code === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }

  public async reply(mail: Mail) {
    const res = await this.api.post<ResponseDTO>('API_MAILS', mail);
    if (res.code === 1) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }

  public async delete(id: number) {
    const res = await this.api.delete('API_MAILS', id.toString());
    if (res.code === 200) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }

  public async derive(mailId: number, responsableId: number) {
    const res = await this.api.post<ResponseDTO>('API_MAILS', { mailId, responsableId }, 'derive');
    if (res.code === 1) {
      return res.message;
    } else {
      throw new Error(res.message);
    }
  }
}
