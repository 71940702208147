import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {

  transform(text: string, num: number): any {
    var formated : string = '';

    for(let i = 0; i < text.length; i++){
      if(i == num || ((i/num) % 2 == 0) || i%num == 0){
        formated += '\n' + text[i];
      }else{
        formated += text[i];
      }
    }
    return formated;
  }

}
