import { Injectable } from '@angular/core';
import { PdfMakeWrapper, Txt } from 'pdfmake-wrapper';
import { Chat, ChatFormResponse, ChatListResponse, ChatMessage, ChatMessageListResponse, UnreadChatsCountResponse } from 'src/app/models/chat';
import { ResponseDTO } from 'src/app/models/ResponseDTO';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { ECommerceFilter } from 'src/app/models/ECommerceFilter';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private audio: HTMLAudioElement;
  constructor(private api: ApiService,private router:Router) {   
     this.audio = new Audio();
    this.audio.src = '../../assets/sound/sond.mp3';
  }

  public async getUnreadChats(accountId: number) {
    const res = await this.api.get<UnreadChatsCountResponse>('API_BASE', 'chat/user/' + accountId);
    if (res.code === 200) {
      if(res.chatsnotRead.chatsNotRead>0){
        if(res.chatsnotRead.chatsNotRead!=Number(localStorage.getItem("messagges")))
        this.audio.play();     
      }
      localStorage.setItem("messagges",res.chatsnotRead.chatsNotRead.toString())
      return res.chatsnotRead;
    } else {
      throw new Error(res.message);
    }
  }

  public async getChatsByAccountId(id: number, filter: ECommerceFilter) {
    const res = await this.api.post<ChatListResponse>('API_BASE', filter, `chat/${id}`);
    if (res.code === 200) {
      return res.chats;
    } else {
      throw new Error(res.message);
    }
  }

  public async getMessagesByChatId(id: number) {
    const res = await this.api.get<ChatMessageListResponse>('API_BASE', 'chat/messages/' + id.toString());
    return res.messages;
  }

  public async sendMessage(message: ChatMessage) {
    const res = await this.api.post<ResponseDTO>('API_BASE', message, 'chat/sendmessage/user');
    if (res.code !== 200) {
      throw new Error(res.message);
    }
  }

  public async markAsRead(chatId: number) {
    const res = await this.api.put<ResponseDTO>('API_BASE', {}, 'chat/' + chatId);
  }

  public async convertToPDF(chat: Chat) {
    const messages = await this.getMessagesByChatId(chat.chatId);
    const pdf = new PdfMakeWrapper();
    messages.forEach(m => {
      const background = m.sendByUser ? '#70AFCE' : '#D3D3D3';
      const alignment = m.sendByUser ? 'right' : 'left';
      const color = m.sendByUser ? 'white' : 'black';
      let message: string;
      if (!m.sendByUser && m.form1Completed) {
        const { form } = m;
        message = `Formulario 1 completado
        Nombre: ${form.name}
        CUIT: ${form.cuit}
        Razón Social: ${form.legalName}
        Domicilio: ${form.address}
        Teléfono: ${form.phoneNumber}
        Pais: ${form.countryName}
        Email: ${form.email}
        Cargo: ${form.occupation}`;
      } else if (!m.sendByUser && m.form2Completed) {
        const { form } = m;
        message = `Formulario 2 completado
        Nombre: ${form.name}
        Cargo: ${form.occupation}
        Email: ${form.email}
        Teléfono: ${form.phoneNumber}
        Razón Social: ${form.legalName}`;
      } else {
        message = m.message;
      }
      pdf.add(new Txt(message)
        .background(background)
        .alignment(alignment)
        .color(color)
        .margin([0, 0, 0, 10])
        .end
      );
    });
    pdf.create().download('chat');
  }

  public async getFormDataByChatId(chatId: number) {
    const res = await this.api.get<ChatFormResponse>('API_BASE', '');
    if (res.code === 200) {
      return res.form;
    } else {
      throw new Error(res.message);
    }
  }
}
