import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate{
   
   constructor( private router: Router ){}

   canActivate(){
        let token = localStorage.getItem('token');
        let account = localStorage.getItem('account');
        if(token && account){
            return true;
        }else{
            this.router.navigate(['/login']);
            return false;
        }
    }
}