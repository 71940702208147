import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseDTO } from '../models/ResponseDTO';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public urls: any;

  constructor(private http: HttpClient) {
    this.getUrls().then(urls => this.urls = urls);
  }

  private getUrls() {
    return this.http.get<any>('../../assets/urls.json').toPromise();
  }

  /**
   * Realiza un llamado GET al backend.
   * @param api nombre de la API enumerada en urls.json
   * @param endpoint adicional que se agregara al final de la API
   * @returns Promise del GET
   */
  public async get<T>(api: string, endpoint?: string, skipToken?: boolean) {
    const urls = await this.getUrls();
    this.urls = urls;
    const url = urls[api] + (endpoint ? '/' + endpoint : '');
    let headers = new HttpHeaders();
    if (!skipToken) {
      headers = headers.set('token', localStorage.getItem('token').split('"').join(''));
    }
    return this.http.get<T>(url, { headers }).toPromise();
  }

  /**
   * Realiza un llamado POST al backend.
   * @param api nombre de la API enumerada en urls.json
   * @param body body del request
   * @param endpoint adicional que se agregara al final de la API
   * @returns Promise del POST
   */
  public async post<T>(api: string, body: any, endpoint?: string, skipToken?: boolean) {
    const urls = await this.getUrls();
    this.urls = urls;
    const url = urls[api] + (endpoint ? '/' + endpoint : '');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    if (!skipToken) {
      headers = headers.set('token', localStorage.getItem('token').split('"').join(''));
    }
    return this.http.post<T>(url, body, { headers }).toPromise();
  }

  /**
   * Realiza un llamado POST al backend enviando FormData.
   * @param api nombre de la API enumerada en urls.json
   * @param formData body del request
   * @param endpoint adicional que se agregara al final de la API
   * @returns Promise del POST
   */
  public async postForm<T>(api: string, formData: FormData, endpoint?: string) {
    const urls = await this.getUrls();
    this.urls = urls;
    const url = urls[api] + (endpoint ? '/' + endpoint : '');
    let headers = new HttpHeaders();
    headers = headers.set('token', localStorage.getItem('token').split('"').join(''));
    return this.http.post<T>(url, formData, { headers }).toPromise();
  }

  /**
   * Realiza un llamado PUT al backend.
   * @param api nombre de la API enumerada en urls.json
   * @param body body del request
   * @param endpoint adicional que se agregara al final de la API
   * @returns Promise del PUT
   */
  public async put<T>(api: string, body: any, endpoint?: string, skipToken?: boolean) {
    const urls = await this.getUrls();
    const url = urls[api] + (endpoint ? '/' + endpoint : '');
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    if (!skipToken) {
      headers = headers.set('token', localStorage.getItem('token').split('"').join(''));
    }
    return this.http.put<T>(url, body, { headers }).toPromise();
  }

  /**
   * Realiza un llamado PUT al backend.
   * @param api nombre de la API enumerada en urls.json
   * @param formData body del request
   * @param endpoint adicional que se agregara al final de la API
   * @returns Promise del PUT
   */
  public async putForm<T>(api: string, formData: FormData, endpoint?: string) {
    const urls = await this.getUrls();
    const url = urls[api] + (endpoint ? '/' + endpoint : '');
    const headers = new HttpHeaders().set('token', localStorage.getItem('token').split('"').join(''));
    return this.http.put<T>(url, formData, { headers }).toPromise();
  }

  public async delete(api: string, endpoint: string) {
    const urls = await this.getUrls();
    const url = urls[api] + (endpoint ? '/' + endpoint : '');
    const headers = new HttpHeaders().set('token', localStorage.getItem('token').split('"').join(''));
    return this.http.delete<ResponseDTO>(url, { headers }).toPromise();
  }
}
