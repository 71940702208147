import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, observable } from 'rxjs';
import { ObserversModule } from '@angular/cdk/observers';
import { UrlService } from '../../assets/url-service.service';

@Injectable({
  providedIn: 'root'
})
export class HasPermissionService {
  myID: number;
  constructor(private http: HttpClient, private urlService: UrlService) { }

  hasPermission(accountID: number){
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
      this.http.get(response.API_PERMISSIONS_HAS_PERMISSION + accountID).subscribe(
        (response:boolean) => {observable.next(response);},
        (error: any) => {
          observable.error(error.message);
        }
      )
    });})
  }

  hasAccountPermission(accountID: number){
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
     this.http.get(response.API_PERMISSIONS_HAS_PERMISSION + 'account/' + accountID).subscribe(
       (response: any) => {
         observable.next(response);
       },
       (error: any) => {
         observable.error(error.message);
       }
     ) 
    });})
}
hasClientPermission(){
  
  return Observable.create(observable => {
    this.urlService.url().subscribe(
      (response: any) => {
   this.http.get(response.API_PERMISSIONS_HAS_PERMISSION + 'client/' + JSON.parse(localStorage.getItem('account')).accountID).subscribe(
     (response: any) => {
       observable.next(response);
     },
     (error: any) => {
       observable.error(error.message);
     }
   ) 
  });})
}

hasGroupPermission(){
  this.myID = JSON.parse(localStorage.getItem('account')).accountID;
  return Observable.create(observable => {
    this.urlService.url().subscribe(
      (response: any) => {
    this.http.get(response.API_PERMISSIONS_HAS_PERMISSION + 'group/' + this.myID
).subscribe(
      (response: any) => {
        observable.next(response);
      },
      (error: any) => {
        observable.error(error.message);
      }
    );
  })})
}

hasDiscount15Permission(){
  this.myID = JSON.parse(localStorage.getItem('account')).accountID;
  return Observable.create(observable => {
    this.urlService.url().subscribe(
      (response: any) => {
    this.http.get(response.API_PERMISSIONS_HAS_PERMISSION + 'discount15/' + this.myID
).subscribe(
      (response: any) => {
        observable.next(response);
      },
      (error: any) => {
        observable.error(error);
      }
    )
  });})
}

  hasQuotationPermissions(accountID: number){
    
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
      this.http.get(response.API_PERMISSIONS_HAS_PERMISSION + 'quotation/' + accountID).subscribe(
        (response: any) => {
          observable.next(response);
        },
        (error: any) => {
          observable.error(error.message);
        }
      )
    });})
  }

  hasSystemAccountPermissions(){
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
          this.http.get(response.API_PERMISSIONS_HAS_PERMISSION + 'system-account/' + JSON.parse(localStorage.getItem('account')).accountID).subscribe(
            (response: any) => {
              observable.next(response);
            },
            (error: any) => {
              observable.error(error.message);
            }
            )
          });})
        }
        
  iGotGroup(group_name: string){
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders().set('token', token.replace("\"", '').replace("\\", ''))
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
      this.http.get(`${response.API_PERMISSIONS_HAS_PERMISSION}got/my-groups`, {headers: headers}).subscribe(
        (resp: any) => {
          console.log(resp);
          if(resp.length > 0){

            observable.next(resp.find(x => x.description == group_name) != null);
          }else{
            observable.next(false);
          }
        },
        (error: any) => {
          observable.error("Ocurrió un error inesperado");
        }
      )
    });})
  }

  myGroups(){
    let token = localStorage.getItem('token');
    let headers = new HttpHeaders().set('token', token.replace("\"", '').replace("\\", ''))
    return Observable.create(observable => {
      this.urlService.url().subscribe(
        (response: any) => {
      this.http.get(`${response.API_PERMISSIONS_HAS_PERMISSION}got/my-groups`, {headers: headers}).subscribe(
        (resp: any) => {
          console.log(resp);
          observable.next(resp);
        },
        (error: any) => {
          observable.error("Ocurrió un error inesperado");
        }
      )
    });})
  }



}
