import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginDTO } from '../models/loginDTO';
import { Observable } from 'rxjs';
import { UrlService } from '../../assets/url-service.service';
import { UserPresenceService } from './ecommerce/user-presence.service';
import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LoginServiceService {

  constructor(private http: HttpClient, private urlService: UrlService, private userPresence: UserPresenceService) { }

  logIn(loginDTO: LoginDTO) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return new Observable(observer => {
      this.urlService.url().subscribe(
        (resp: any) => {
          this.http.post(resp.API_LOGIN, loginDTO, { headers: headers }).subscribe( 
            (response: any) =>  observer.next(response),
            error => observer.error(error)
          );
        });
    });
  }

  public async validateSession() {
    let headers = new HttpHeaders();
    headers = headers.set('token', localStorage.getItem('token').split('"').join(''));
    const urlRes = await this.urlService.url().pipe(take(1)).toPromise();
    const url = urlRes.API_LOGIN;
    const res = await this.http.get<{ isValidSession: boolean }>(url, { headers }).pipe(take(1)).toPromise();
    return res.isValidSession;
  }

  logOut() {
    this.userPresence.setStatus(false);
    localStorage.clear();
  }

}
