import { Pipe, PipeTransform } from '@angular/core';
import { QuotationDTO } from '../models/quotationDTO';

@Pipe({
  name: 'mainPanel'
})
export class MainPanelPipe implements PipeTransform {

  transform(value: QuotationDTO[], id: number): any {
    const arr = []
    for(let i = 0; i < value.length; i++){
      if(value[i].accountID == id){
        arr.push(value[i]);
      }
    }
    return arr;
  }

}
