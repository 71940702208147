import { Component, OnInit, HostListener } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { LoginServiceService } from '../services/login.service';
import { NavigationEnd, Router } from '@angular/router';
import { ToastService } from '../services/components/toast.service';
import { HasPermissionService } from '../services/has-permission.service';
import { UsersService } from '../services/users.service';
import { LoginDetailsDTO } from '../models/loginDetailsDTO';
import { ChatService } from '../services/ecommerce/chat.service';
import swal from 'sweetalert2';
import { QuotationsService } from '../services/quotations.service';
import { OpenedQuotation } from '../models/digital-quotation';
import { DatePipe } from '@angular/common';
import { SignalrService } from '../services/socket/signalr.service';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { UserAccountDTO } from '../models/userAccountDTO';
import { environment } from 'src/environments/environment';
import { MailService } from '../services/ecommerce/mail.service';

declare const $: any;

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}


@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    chieff = false;
    public menuItems: any[];
    ps: any;
    name: string;
    hasPermission: boolean;
    urlImg: string;
    isAdmin: boolean;
    my = JSON.parse(localStorage.getItem('account')) as UserAccountDTO;
    public hasUnreadMessages: boolean;
    public hasUnreadMails: boolean;
    public hasQuotationUnread: boolean;
    public quotationIds: number[] = [];
    public hubConnection: HubConnection | any;
    private urlSocket = environment.socket
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }


    constructor(private loginService: LoginServiceService,
        private router: Router,
        private permission: HasPermissionService,
        private userService: UsersService,
        private toastService: ToastService,
        private chatService: ChatService,
        private mailsService: MailService,
        private quotationService: QuotationsService,
        private datePipe: DatePipe,
        private socket: SignalrService
    ) {

    }
    // poder escuchar si sale de la pagina

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any): void {
        this.userService.Offline(this.my.accountID);
        this.socket.desconnected();
        $event.returnValue = true;
    }

    ngOnInit() {
        //socket
        let builder = new HubConnectionBuilder();
        this.hubConnection = builder.withUrl(this.urlSocket).build();
        this.hubConnection.on("Message", (mensaje: any) => {
            setTimeout(async () => {
                const account = JSON.parse(localStorage.getItem('account')) as LoginDetailsDTO;
                const res = await this.chatService.getUnreadChats(account.accountID);
                this.hasUnreadMessages = res.chatsNotRead > 0;
                getQuotationInteractions();
            }, 2000);
        })

        this.hubConnection.start();

        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                console.log('La pestaña está oculta.');
                this.userService.Offline(this.my.accountID);
                this.socket.desconnected();

            } else {
                console.log('La pestaña está visible.');
                this.userService.Online(this.my.accountID).subscribe(
                    (response: any) => {
                        if (response.code == 200) {
                            console.log(response)
                        }
                    });
                this.socket.connected();
            }
        });
        const showChatAlert = () => swal({
            title: 'Tiene mensajes de chat sin leer',
            showCancelButton: true,
            confirmButtonText: '<span>Ir a mensajes</span>',
            confirmButtonClass: 'btn btn-primary',
            cancelButtonText: '<span>Cerrar</span>',
            cancelButtonClass: 'btn btn-link',
            buttonsStyling: false
        }).then(res => {
            if (!res.dismiss) { this.router.navigate(['/dashboard/chats']); }
        });
        const showMailsAlert = () => swal({
            title: 'Tiene mails sin leer',
            showCancelButton: true,
            confirmButtonText: '<span>Ir a mails</span>',
            confirmButtonClass: 'btn btn-primary',
            cancelButtonText: '<span>Cerrar</span>',
            cancelButtonClass: 'btn btn-link',
            buttonsStyling: false
        }).then(res => {
            if (!res.dismiss) { this.router.navigate(['/dashboard/mails']); }
        });

        const showQuotationAlert = () => {
            const multiple = this.quotationIds.length > 1;
            const middleText = (multiple ? 'de los presupuestos ' : 'del presupuesto ') + this.quotationIds.join(', ');
            const title = `Tiene mensajes sin leer ${middleText}`;
            const confirmText = multiple ? 'Ir a presupuestos' : 'Ver mensajes';
            swal({
                title,
                showCancelButton: true,
                confirmButtonText: `<span>${confirmText}</span>`,
                confirmButtonClass: 'btn btn-primary',
                cancelButtonText: '<span>Cerrar</span>',
                cancelButtonClass: 'btn btn-link',
                buttonsStyling: false
            }).then(res => {
                if (!res.dismiss) {
                    if (multiple) {
                        this.router.navigate(['//dashboard/master-tables/quotations']);
                    } else {
                        const quotationId = this.quotationIds[0];
                        this.router.navigate([`/dashboard/master-tables/quotations/${quotationId}/messages`]);
                    }
                }
            });
        };

        this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            if (event.url != '/dashboard/chats' && this.hasUnreadMessages) {
                showChatAlert();
            } else if (!event.url.includes('mails') && this.hasUnreadMails) {
                showMailsAlert();
            } else if (!event.url.includes('quotation') && this.hasQuotationUnread) {
                showQuotationAlert();
            }
            // check if user has unread messages
            this.mailsService.getUnreadMails(accountID).then((res) => {
                this.hasUnreadMails = res.mailsNotRead > 0;
            });
        });

        this.getImg();
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

        const account = JSON.parse(localStorage.getItem('account')) as LoginDetailsDTO;
        const accountID = account.accountID;
        this.name = account.firstName + ' ' + account.lastName;
        this.permission.hasPermission(accountID).subscribe(
            (response: boolean) => {
                this.hasPermission = response;
            },
            (error: string) => {

            }
        );
        this.iGotGroup();

        const getQuotationInteractions = async () => {
            const { quotationIdsWithUnreadInteractions: unreadIds, openQuotations }
                = await this.quotationService.getUnreadMessages(accountID);
            this.quotationIds = unreadIds;
            this.hasQuotationUnread = unreadIds.length > 0;

            let html = '';
            if (!openQuotations.length) return;
            openQuotations.forEach(quotation => {
                const date = this.datePipe.transform(quotation.dateOpened, 'dd/MM/yy \'a las\' HH:mm', 'GMT-6');
                html += (html == '' ? '' : '<br>') +
                    `El contacto ${quotation.contactName} ha abierto el presupuesto ${quotation.quotationId} el día ${date}`;
            });
            swal({
                title: 'Nuevas visualizaciones de presupuesto',
                html,
                confirmButtonText: '<span>Ok</span>',
                confirmButtonClass: 'btn btn-primary',
                buttonsStyling: false
            });
        };

        // check if user has unread messages
        this.chatService.getUnreadChats(accountID).then((res) => {

            this.hasUnreadMessages = res.chatsNotRead > 0;
            if (this.hasUnreadMessages) {
                showChatAlert();
            } else getQuotationInteractions();
        });
        // check if user has unread messages
        this.mailsService.getUnreadMails(accountID).then((res) => {

            this.hasUnreadMails = res.mailsNotRead > 0;
            if (this.hasUnreadMails) {
                showMailsAlert();
            }
        });
        this.hasUnreadMessages = false;
        const roles = account.roleIDs;
        this.isAdmin = Boolean(roles && roles.includes(1));
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    logOut() {
        this.loginService.logOut();
        this.router.navigate(['/login']);
    }

    getImg() {
        this.userService.getImg().subscribe(
            (response: any) => {
                this.urlImg = response.message;
            },
            (error: string) => {
                this.toastService.showNotification(error);
            }
        );
    }

    iGotGroup() {
        this.permission.iGotGroup('Jefe de ventas').subscribe(
            (response: boolean) => {
                this.chieff = response;
            }
        );
    }

}
