import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginDetailsDTO } from '../models/loginDetailsDTO';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate() {
    const token = localStorage.getItem('token');
    const account = localStorage.getItem('account');
    if (token && account) {
      const currentUser = JSON.parse(account) as LoginDetailsDTO;
      const roles = currentUser.roleIDs;
      if (roles && roles.includes(1)) {
        return true;
      } else {
        this.router.navigate(['dashboard/main-panel']);
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
