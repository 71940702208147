import { Pipe, PipeTransform } from '@angular/core';
import { AccountPermissionDTO } from '../models/accountPermissionDTO';

@Pipe({
  name: 'filterPermissions'
})
export class FilterPermissionsPipe implements PipeTransform {

  transform(value: AccountPermissionDTO[], arg: string): any {
    const dataFilter = [];
    arg = arg.toUpperCase().replace(/ /g, '');
    for(let i = 0; i < value.length; i++){
      if(value[i].permissionES.toUpperCase().replace(/ /g, '').includes(arg)){
        dataFilter.push(value[i]);
      }
    }
    return dataFilter;
  }

}
