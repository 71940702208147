import { Pipe, PipeTransform } from '@angular/core';
import { Mail } from '../models/mails';

@Pipe({
  name: 'filterMails'
})
export class FilterMailsPipe implements PipeTransform {

  transform(value: Mail[], arg: string): Mail[] {
    if (!arg.trim()) {
      return value;
    }
    arg = arg.toUpperCase().replace(/ /g, '');
    return value.filter(mail =>
      mail.name.toUpperCase().replace(/ /g, '').includes(arg) ||
      mail.email.toUpperCase().replace(/ /g, '').includes(arg) ||
      mail.subject.toUpperCase().replace(/ /g, '').includes(arg) ||
      (mail.replied && 'RESPONDIDO'.includes(arg)) ||
      (!mail.replied && mail.asRead && 'LEÍDO'.includes(arg)) ||
      (!mail.replied && !mail.asRead && 'SINLEER'.includes(arg))
    );
  }

}
