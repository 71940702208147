import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SidebarPortalComponent } from './sidebar-portal.component';

@NgModule({
    imports: [ RouterModule, CommonModule ],
    declarations: [ SidebarPortalComponent ],
    exports: [ SidebarPortalComponent ]
})

export class SidebarPortalModule {}
