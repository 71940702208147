import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class NoIdentityGuard implements CanActivate{
   
   constructor(private router: Router ){}

   canActivate(){
    let token = localStorage.getItem('token');
    let account = localStorage.getItem('account');
        if(token && account){
            this.router.navigate(['/dashboard']);
            return false;
        }else{
            return true;
        }
    }
}