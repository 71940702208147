import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(private http: HttpClient) { }
  url(){
    return Observable.create(observable => {
      this.http.get('./assets/urls.json').subscribe(
        (response: any) => {
          observable.next(response);
        }
      )
    })
  }
}
