import { Pipe, PipeTransform } from '@angular/core';
import { ProductDTO } from '../../models/productDTO';
import { CountryDTO } from '../../models/countryDTO';

@Pipe({
  name: 'productCountries'
})
export class ProductCountriesPipe implements PipeTransform {

  transform(products: ProductDTO[], country: CountryDTO): any {
    return products.filter(p => !country || !country.countryID || p.countryID == country.countryID);
  }

}
