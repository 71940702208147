import { Pipe, PipeTransform } from '@angular/core';
import { ProcessDTO } from '../models/process';

@Pipe({
  name: 'process'
})
export class ProcessPipe implements PipeTransform {

  transform(processes: ProcessDTO[], filter: boolean, filterString: string): ProcessDTO[] {
    const arr: ProcessDTO[] = [];
    if(processes == null || processes.length == 0){
      return arr;
    }else{
      for(let i = 0; i < processes.length; i++){
        if(filter){
          if(processes[i].industry == 'Farmacéutico' && this.filterFields(filterString, processes[i])){
            arr.push(processes[i]);
          }
        }else{
          if(processes[i].industry != 'Farmacéutico' && this.filterFields(filterString, processes[i])){
            arr.push(processes[i]);
          }
        }
      }
      return arr;
    }

  }

  filterFields(f: string, p: ProcessDTO): boolean{
    console.log(f);
    if(f == null || f.replace(/ /g, '') == ''){
      return true;
    }
    f = f.toUpperCase().replace(/ /g, '');
    let flag = false;
    if((p.clientID != null && p.clientID.toString().toUpperCase().replace(/ /g, '').includes(f)) ||  
      (p.activePrinciple != null && p.activePrinciple.toString().replace(/ /g, '').toUpperCase().includes(f) )||
      (p.clientLegalName != null && p.clientLegalName.toString().replace(/ /g, '').toUpperCase().includes(f) )||
      (p.competingProduct != null && p.competingProduct.toString().replace(/ /g, '').toUpperCase().includes(f) )||
      (p.concentration && p.concentration.toString().toUpperCase().replace(/ /g, '').includes(f) )||
      (p.description && p.description.toString().toUpperCase().replace(/ /g, '').includes(f) )||
      (p.fcProduct && p.fcProduct.toString().toUpperCase().replace(/ /g, '').includes(f) )||
      (p.filtrationStages && p.filtrationStages.toString().replace(/ /g, '').toUpperCase().includes(f) )||
      (p.fluid && p.fluid.toString().toUpperCase().replace(/ /g, '').includes(f) )||
      (p.lotSize && p.lotSize.toString().toUpperCase().replace(/ /g, '').includes(f) )||
      (p.type && p.type.toString().toUpperCase().replace(/ /g, '').includes(f) )
     ){
       return true;
      }else{
        return false;
     }
  }

}

/*public processID:number;
    public activePrinciple:string;
    public lotSize:number;
    public filtrationStages:number;
    public competingProduct:string;
    public fcProduct:string;
    public description:string;
    public clientID:number;
    public enabled:boolean;
    public fluid:string;
    public industry:string;
    public concentration:number;
    public clientLegalName:string;
    public type:string;*/
