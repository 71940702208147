import { Pipe, PipeTransform } from '@angular/core';
import { QuotationDTO } from '../models/quotationDTO';

@Pipe({
  name: 'quotationTraking'
})
export class QuotationTrakingPipe implements PipeTransform {

  transform(value: QuotationDTO[]): any {
    return value.sort((a, b) => {
      const aDate = new Date(a.trackingDate);
      const bDate = new Date(b.trackingDate);
      return aDate.getTime() - bDate.getTime();
    });
  }

}
