import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterAccPipe } from './filter.pipe';
import { FilterGroupsPipe } from './filter-groups.pipe';
import { FilterPermissionsPipe } from './filter-permissions.pipe';
import { FilterClientsPipe } from './filter-clients.pipe';
import { FilterProductsPipe } from './filter-products.pipe';
import { UpdateDiscountsPipe } from './update-discounts.pipe';
import { FilterContactsPipe } from './filter-contacts.pipe';
import { PaginatePipe } from './paginate.pipe';
import { RoundUpPipe } from './round-up.pipe';
import { FilterGeneralParameterPipe } from './filter-general-parameter.pipe';
import { MaxLengthPipe } from './max-length.pipe';
import { QuotationFilterPipe } from './quotation-filter.pipe';
import { NumberToWordPipe } from './number-to-word.pipe';
import { QuotationItemFilterPipe } from './quotation-item-filter.pipe';
import { SortPipe } from './generic/sort.pipe';
import { FilterDatePipe } from './filter-date.pipe';
import { DiscountCountriesPipe } from './countries/discount-countries.pipe';
import { ProductCountriesPipe } from './countries/product-countries.pipe';
import { MainPanelPipe } from './main-panel.pipe';
import { ProcessPipe } from './process.pipe';
import { SortCommentPipe } from './sort-comment.pipe';
import { FormatTextPipe } from './format-text.pipe';
import { RevertArrPipe } from './revert-arr.pipe';
import { FilterPipe } from './generic/filter.pipe';
import { DatePipePipe } from './date-pipe.pipe';
import { FilterChatsPipe } from './filter-chats.pipe';
import { FilterMailsPipe } from './filter-mails.pipe';
import { QuotationTrakingPipe } from './quotation-traking.pipe';

@NgModule({
  declarations: [
    FilterAccPipe,
    FilterGroupsPipe,
    FilterPermissionsPipe,
    FilterClientsPipe,
    FilterProductsPipe,
    UpdateDiscountsPipe,
    FilterContactsPipe,
    PaginatePipe,
    RoundUpPipe,
    FilterGeneralParameterPipe,
    MaxLengthPipe,
    QuotationFilterPipe,
    NumberToWordPipe,
    QuotationItemFilterPipe,
    SortPipe,
    FilterDatePipe,
    DiscountCountriesPipe,
    ProductCountriesPipe,
    MainPanelPipe,
    ProcessPipe,
    SortCommentPipe,
    FormatTextPipe,
    RevertArrPipe,
    FilterPipe, DatePipePipe, FilterChatsPipe, FilterMailsPipe, QuotationTrakingPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilterGroupsPipe,
    FilterPermissionsPipe,
    FilterClientsPipe,
    FilterProductsPipe,
    UpdateDiscountsPipe,
    FilterContactsPipe,
    PaginatePipe,
    RoundUpPipe,
    FilterGeneralParameterPipe,
    MaxLengthPipe,
    QuotationFilterPipe,
    NumberToWordPipe,
    QuotationItemFilterPipe,
    SortPipe,
    FilterDatePipe,
    DiscountCountriesPipe,
    ProductCountriesPipe,
    MainPanelPipe,
    ProcessPipe,
    SortCommentPipe,
    FormatTextPipe,
    RevertArrPipe,
    FilterAccPipe,
    FilterPipe,
    DatePipePipe,
    FilterChatsPipe,
    FilterMailsPipe,
    QuotationTrakingPipe
  ]
})
export class PipesModule { }
